import React, { useState, useEffect } from "react";
import { graphql } from "gatsby";
import { navigate } from "@reach/router";
import useUser from "../lib/auth";
import { sortArrayAlphabetically } from "../lib/utils/helpers";
import SidebarLayout from "../layouts/SidebarLayout";
import TreeNav from "../components/TreeNav";
import Select from "../components/elements/Select";
import { createNavMenu, createSearchMenu } from "../lib/utils/dictionaryFuncs";
import VisualLanguageMedia from "../components/VisualLanguageMedia";

export const DictionaryPageQuery = graphql`
  query {
    heroBg: file(relativePath: { eq: "hero-bg-2.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: FIXED)
      }
      extension
      publicURL
    }
    allDataJson {
      edges {
        node {
          id
        }
      }
    }
    allDictionary {
      edges {
        node {
          id
          skosId
          type
          prefLabel {
            _language
            _value
          }
          altLabel {
            _language
            _value
          }
          definition {
            _language
            _value
          }
          example {
            _language
            _value
          }
          note {
            _language
            _value
          }
          broader {
            _id
          }
          narrower {
            _id
          }
          inScheme {
            _id
          }
          topConceptOf {
            _id
          }
        }
      }
    }
  }
`;

const VocabularyConcept = ({ concept, schemes }) => {
  // Return the English language preferred label
  const englishLabel = (c) =>
    c.prefLabel
      .filter((d) => (d["_language"] = "en"))
      .map((d) => d["_value"])[0];

  // Return the English language definition
  const definition = (c) =>
    c.definition
      .filter((d) => (d["_language"] = "en"))
      .map((d) => d["_value"])[0];

  // Return JSX for fields where there may 0 - n items
  const multipleItems = (c, fieldName, heading) => {
    if (!c[fieldName].length) return <></>; // No items in field
    return (
      <>
        <h4>{c[fieldName].length === 1 ? heading : `${heading}s`}</h4>
        {c[fieldName].map((d) => (
          <div key={`${fieldName}-${d._value}`}>{d._value}</div>
        ))}
      </>
    );
  };

  //Return JSX listing all the schemes this concept is in
  const schemeName = (sId) => schemes.filter((s) => s.skosId === sId)[0];
  const inScheme = (c) => {
    if (!c.inScheme || c.inScheme.length === 0) return;
    return (
      <>
        <h4>{c.inScheme.length === 1 ? "In Scheme" : "In Schemes"}</h4>
        {c.inScheme.map((d) => (
          <div key={`scheme-${d._id}`}>{englishLabel(schemeName(d._id))}</div>
        ))}
      </>
    );
  };

  // Return either the concept/term definition or a generic explanatory page
  if (concept && concept.type === "Concept") {
    return (
      <div className="content">
        {concept && (
          <>
            <h2>
              {concept.type === "Concept" ? englishLabel(concept) : <></>}
            </h2>
            <div>{definition(concept)}</div>
            <div>{multipleItems(concept, "altLabel", "Synonym")}</div>
            <div>{multipleItems(concept, "example", "Example")}</div>
            <div>{multipleItems(concept, "note", "Note")}</div>
            <div>{inScheme(concept)}</div>
          </>
        )}
      </div>
    );
  } else if (concept && concept.type === "ConceptScheme") {
    return (
      <div className="content">
        {concept && (
          <>
            <h2>
              {concept.type === "ConceptScheme" ? englishLabel(concept) : <></>}
            </h2>
            <div>{definition(concept)}</div>
            <br />
            <br />
            <div>
              Select a term from the menu to see its definition and other
              information.
            </div>
          </>
        )}
      </div>
    );
  }
  // console.log(schemes);
  return (
    <div className="content">
      <h2>Vocabulary Schemes</h2>
      <div>
        The vocabulary is broken into schemes (groupings of associated terms),
        use the menu to the left to select a scheme and one of its terms to view
        its definition and other information. Terms can also be searched for
        with the search box above.
      </div>
      <br />
      <div>
        {schemes.map((s, index) => (
          <div key={index}>
            <div>
              <b>{englishLabel(s)}: </b>
              {definition(s)}
            </div>
            <br />
          </div>
        ))}
      </div>
    </div>
  );
};

const createScheme = (scheme) =>
  scheme.reduce(
    (str, s) =>
      str !== ""
        ? `${str} & ${s.split("vmc:s-").pop()}`
        : s.split("vmc:s-").pop(),
    ""
  );

const VocabularyPage = ({ location, data }) => {
  const [activeItem, setActiveItem] = useState(null);
  const { loading, isAuthenticated } = useUser({ required: true });

  const dictionary = data.allDictionary.edges.map(({ node }) => node);
  const schemes = dictionary.filter((n) => n.type === "ConceptScheme");
  const skosNavItems = createNavMenu(dictionary); // Create a navigation menu
  const skosSearchItems = createSearchMenu(skosNavItems);

  const options = skosSearchItems.map(({ key, label, inScheme }) => ({
    value: key,
    label,
    description: `${createScheme(inScheme)}`,
    slug: `/vmc#${key.split("vmc:").pop()}`,
  }));

  const pageTitle = activeItem?.prefLabel?.[0]?._value
    ? `Vocabulary: ${activeItem.prefLabel[0]._value}`
    : "Vocabulary";
  const meta = {
    // description: "",
    // ogTitle: "",
    // ogDescription: "",
    // ogType: "",
  };

  useEffect(() => {
    if (location.hash) {
      const id = "vmc:" + location.hash.split("#").pop();
      const match = dictionary.find(({ skosId }) => skosId === id);
      if (match && match !== activeItem) setActiveItem(match);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const handleSearchSelection = ({ value, slug }) => {
    const match = dictionary.find(({ skosId }) => skosId === value);
    if (match) {
      setActiveItem(match);
      navigate(slug);
    }
    console.log("handleSearchSelection...", match);
  };

  const handleNavSelection = (selected) => {
    const match = dictionary.find(({ skosId }) => skosId === selected);
    if (match) setActiveItem(match);
    console.log("handleTreeNavSelection...", match);
  };

  if (loading || !isAuthenticated) return null;

  return (
    <SidebarLayout
      pageTitle={pageTitle}
      meta={meta}
      backgroundImage={data.heroBg}
      // topBar={
      //   <Select
      //     options={sortArrayAlphabetically(options, "label", true)}
      //     defaultValue={activeItem?.skosId}
      //     onChange={handleSearchSelection}
      //     // size="sm"
      //     // maxW="400px"
      //   />
      // }
      leftSidebar={
        <>
          <Select
            options={sortArrayAlphabetically(options, "label", true)}
            defaultValue={activeItem?.skosId}
            onChange={handleSearchSelection}
            mb={5}
          />
          <TreeNav
            data={skosNavItems}
            location={location}
            defaultKey={activeItem?.skosId}
            onSelection={handleNavSelection}
          />
        </>
      }
      leftSidebarWidth="400px"
    >
      <VocabularyConcept concept={activeItem} schemes={schemes} />
      <VisualLanguageMedia
        id={activeItem?.skosId.split(":")[1]}
        title=""
        description=""
      />
    </SidebarLayout>
  );
};

export default VocabularyPage;
