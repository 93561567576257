import config from "../../config";
import React from "react";
import {
  Box,
  Center,
  Heading,
  // Text,
  Button,
  Image,
  // useToast,
} from "@chakra-ui/react";

// const downloadFile = (path, filename, callback) => {
//   console.log(path);

//   fetch(path, {
//     method: "POST",
//     headers: {
//       "Content-Type": "application/json",
//       // Authorization: "Bearer XXXXXXXXXXX",
//     },
//   })
//     .then(async (response) => {
//       // const disposition = response.headers.get("content-disposition");
//       // filename = disposition.match(/filename=(.+)/)[1];
//       return response.blob();
//     })
//     .then((blob) => {
//       console.log(blob);

//       const url = window.URL.createObjectURL(blob);
//       const a = document.createElement("a");
//       a.href = url;
//       a.download = filename;
//       document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
//       a.click();
//       a.remove(); //afterwards we remove the element again
//       if (callback)
//         callback({
//           message: `${filename
//             .split(".")
//             .reverse()[0]
//             .toUpperCase()} Downloaded`,
//         });
//     });
// };

export default function VisualLanguageMedia({
  id,
  title = "",
  description = "",
}) {
  // const toast = useToast();
  const vislangObj = config.visualLanguageIcons[id];

  if (!vislangObj?.image) return null;

  // eslint-disable-next-line
  const filename = vislangObj.download.replace(/^.*[\\\/]/, "");
  const extension = filename.split(".").reverse()[0];

  return (
    <Box maxW="400px" my={8} mt={14}>
      <Box mb={5}>
        <Heading as="h4" color="blue.600" fontSize="2xl" mb={3}>
          {title ? title : "Image"}
        </Heading>
        {/*<Text>*/}
        {/*  {description*/}
        {/*    ? description*/}
        {/*    : "A two-dimensional visual representation"}*/}
        {/*</Text>*/}
      </Box>
      <Center px={10} py={10} borderWidth={1} borderRadius={4}>
        <Image src={vislangObj.image} alt={vislangObj.label} boxSize="120px" />
      </Center>
      {vislangObj.download && (
        <Button
          as="a"
          colorScheme="blue"
          textTransform="uppercase"
          w="full"
          mt={5}
          borderRadius={4}
          href={vislangObj.download}
          download={filename}
        >
          Download {extension.toUpperCase()}
        </Button>
      )}

      {/* {vislangObj.download && (
        <Button
          colorScheme="blue"
          textTransform="uppercase"
          w="full"
          mt={5}
          borderRadius={4}
          onClick={() =>
            downloadFile(vislangObj.download, filename, ({ message }) =>
              toast({
                title: message,
                duration: 2000,
                isClosable: true,
              })
            )
          }
        >
          Download {extension.toUpperCase()}
        </Button>
      )} */}
    </Box>
  );
}
