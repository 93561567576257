import { hasProp } from "./utilities";
import { sortTree } from './helpers';

export function createSearchMenu(schema) {
    const searchList = [];
    const trackDupes = {};

    function traverseConcepts(concepts, terms = []) {
        concepts.forEach((concept) => {
            if (!hasProp(trackDupes, concept.skosId)) {
                trackDupes[concept.skosId] = true;
                terms.push(concept);
                if (hasProp(concept, "children"))
                    traverseConcepts(concept.children, terms);
            }
        });
        return terms;
    }

    schema.forEach((scheme) => {
        const terms = traverseConcepts(scheme.children);
        searchList.push(...terms);
    });
    // console.log(searchList);

    return searchList;
}

export function createNavMenu(json) {
    const schemes = {};
    const concepts = {};

    const createMenuItem = (id) => ({
        key: id,
        skosId: id,
        children: [],
        depth: null,
    }); // Create a new Concept entry

    const englishLabel = (j) =>
        j.prefLabel.filter((d) => d["_language"]).map((d) => d["_value"])[0];
    const createSlug = (k) => `/vmc#${k.split("vmc:").pop()}`;

    const addToScheme = ((sId, concept) => {
        if (!concept.inScheme.includes(sId)) return false

        const c = { ...concept, ...{ children: [] } }; // Only add this if it is in the scheme
        concept.children.forEach((narrowerConcept) => {
            const sc = addToScheme(sId, narrowerConcept);
            if (sc) c.children.push(sc);
        });
        return c;
    });

    json.forEach((j) => {
        const { skosId, broader } = j;

        if (j.type === "ConceptScheme") {
            // If this is a scheme, create an entry in the schemes
            schemes[skosId] = schemes[skosId] || createMenuItem(skosId);
            schemes[skosId].label = englishLabel(j); // Add the English language label
            schemes[skosId].slug = createSlug(schemes[skosId].skosId);
        } else if (j.type === "Concept") {
            // If this is a concept, create an entry in the concepts
            // If there is no entry for this id, then create one
            concepts[skosId] = concepts[skosId] || createMenuItem(skosId);
            concepts[skosId].topConceptOf = j.topConceptOf.map((s) => s['_id']); // Add any topConcepts and the English label
            concepts[skosId].label = englishLabel(j);
            concepts[skosId].slug = createSlug(concepts[skosId].skosId);
            concepts[skosId].inScheme = j.inScheme.map((s) => s['_id']);

            // Add this skos concept as a child to any broader relationships
            broader.forEach(({ _id: nId }) => {
                concepts[nId] = concepts[nId] || createMenuItem(nId);
                concepts[nId].children.push(concepts[skosId]);
            });
        }
    });

    // Add concepts to the menu dropdown for each scheme
    Object.keys(concepts).forEach((skosId) => {
        const concept = concepts[skosId];

        if(!concept.inScheme) return;

        concept.inScheme.forEach((sId) => {
            if(concept.topConceptOf.includes(sId)) {
                const sc = addToScheme(sId, concept);
                if (sc) schemes[sId].children.push(sc);
            }
        });
    });

    // convert to array
    const vocabNavMenu = Object.keys(schemes).map((key) => schemes[key]);
    sortTree(vocabNavMenu);
    return vocabNavMenu;
}
